import { registerPlugins } from '@/plugins'
import { ViteSSG } from 'vite-ssg'
import { createMemoryHistory, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import { routes as autoRoutes } from 'vue-router/auto-routes'
import NProgress from 'nprogress'
import App from '@/App.vue'
import '@/styles/app.scss'

import 'core-js'

const isServer = typeof window === 'undefined'

export const createApp = ViteSSG(
  App,
  {
    history: isServer
      ? createMemoryHistory(import.meta.env.BASE_URL)
      : createWebHistory(import.meta.env.BASE_URL),
    routes: setupLayouts(autoRoutes),
    scrollBehavior(to) {
      if (to.hash) {
        const el = document.querySelector(to.hash)

        return el
          ? {
              top: (el as HTMLElement).offsetTop,
              behavior: 'smooth',
            }
          : { top: 0 }
      } else {
        return { top: 0 }
      }
    },
  },
  ({ app, router }) => {
    registerPlugins(app, router)

    if (isServer) {
      return
    }

    router.beforeResolve((to, from, next) => {
      if (to.hash) {
        const el = document.querySelector(to.hash)
        if (el) {
          window.scrollTo({
            top: (el as HTMLElement).offsetTop,
            behavior: 'smooth',
          })
          return
        }
      }
      if (to.name) {
        NProgress.start()
      }
      next()
    })

    router.afterEach(() => {
      NProgress.done()
    })

    window.addEventListener('vite:preloadError', () => {
      window.location.reload()
    })
  },
)
