import { Plugin } from 'vue'
import VueGtag from 'vue-gtag'
import { Router } from 'vue-router'

const plugin: Plugin = {
  install(app, router: Router) {
    const id = import.meta.env.VITE_GTAG_ID
    if (!id) {
      console.warn('GTag ID is not provided')
      return
    }
    app.use(
      VueGtag,
      {
        config: {
          id,
        },
      },
      router,
    )
  },
}

export default plugin
