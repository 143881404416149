import _definePage_default_0 from '/home/node/app/src/pages/blog/2024-01-03-psycholog-czy-psychiatra.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/node/app/src/pages/blog/2024-02-05-wizyta-u-psychiatry-7-krokow-przed.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/home/node/app/src/pages/blog/2024-03-01-nastolatek-u-psychiatry-z-rodzicem-czy-sam.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/home/node/app/src/pages/blog/2024-04-20-objawy-niepozadane-lekow-psychiatrycznych.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/home/node/app/src/pages/blog/2024-05-05-efekt-pandemii.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/home/node/app/src/pages/blog/2024-06-01-rodzicielstwo-a-zdrowie-psychiczne.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/home/node/app/src/pages/blog/2024-07-10-kontrowersje-wokoll-adhd-u-doroslych.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/home/node/app/src/pages/blog/2024-08-29-adhd-zaburzenie-aktywnosci-czy-funkcji-wykonawczych.vue?definePage&vue&lang.tsx'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/node/app/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/blog',
    /* internal name: '/blog' */
    /* no component */
    children: [
      {
        path: '',
        name: '/blog/',
        component: () => import('/home/node/app/src/pages/blog/index.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: '2024-01-03-psycholog-czy-psychiatra',
        name: '/blog/2024-01-03-psycholog-czy-psychiatra',
        component: () => import('/home/node/app/src/pages/blog/2024-01-03-psycholog-czy-psychiatra.vue'),
        /* no children */
      },
  _definePage_default_0
  ),
  _mergeRouteRecord(
      {
        path: '2024-02-05-wizyta-u-psychiatry-7-krokow-przed',
        name: '/blog/2024-02-05-wizyta-u-psychiatry-7-krokow-przed',
        component: () => import('/home/node/app/src/pages/blog/2024-02-05-wizyta-u-psychiatry-7-krokow-przed.vue'),
        /* no children */
      },
  _definePage_default_2
  ),
  _mergeRouteRecord(
      {
        path: '2024-03-01-nastolatek-u-psychiatry-z-rodzicem-czy-sam',
        name: '/blog/2024-03-01-nastolatek-u-psychiatry-z-rodzicem-czy-sam',
        component: () => import('/home/node/app/src/pages/blog/2024-03-01-nastolatek-u-psychiatry-z-rodzicem-czy-sam.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: '2024-04-20-objawy-niepozadane-lekow-psychiatrycznych',
        name: '/blog/2024-04-20-objawy-niepozadane-lekow-psychiatrycznych',
        component: () => import('/home/node/app/src/pages/blog/2024-04-20-objawy-niepozadane-lekow-psychiatrycznych.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
  _mergeRouteRecord(
      {
        path: '2024-05-05-efekt-pandemii',
        name: '/blog/2024-05-05-efekt-pandemii',
        component: () => import('/home/node/app/src/pages/blog/2024-05-05-efekt-pandemii.vue'),
        /* no children */
      },
  _definePage_default_5
  ),
  _mergeRouteRecord(
      {
        path: '2024-06-01-rodzicielstwo-a-zdrowie-psychiczne',
        name: '/blog/2024-06-01-rodzicielstwo-a-zdrowie-psychiczne',
        component: () => import('/home/node/app/src/pages/blog/2024-06-01-rodzicielstwo-a-zdrowie-psychiczne.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: '2024-07-10-kontrowersje-wokoll-adhd-u-doroslych',
        name: '/blog/2024-07-10-kontrowersje-wokoll-adhd-u-doroslych',
        component: () => import('/home/node/app/src/pages/blog/2024-07-10-kontrowersje-wokoll-adhd-u-doroslych.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
  _mergeRouteRecord(
      {
        path: '2024-08-29-adhd-zaburzenie-aktywnosci-czy-funkcji-wykonawczych',
        name: '/blog/2024-08-29-adhd-zaburzenie-aktywnosci-czy-funkcji-wykonawczych',
        component: () => import('/home/node/app/src/pages/blog/2024-08-29-adhd-zaburzenie-aktywnosci-czy-funkcji-wykonawczych.vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  {
    path: '/jak-pomagamy',
    name: '/jak-pomagamy',
    component: () => import('/home/node/app/src/pages/jak-pomagamy.vue'),
    /* no children */
  },
  {
    path: '/lekarze',
    /* internal name: '/lekarze' */
    /* no component */
    children: [
      {
        path: 'aleksandra-jankowska',
        name: '/lekarze/aleksandra-jankowska',
        component: () => import('/home/node/app/src/pages/lekarze/aleksandra-jankowska.vue'),
        /* no children */
      },
      {
        path: 'katarzyna-kopyto',
        name: '/lekarze/katarzyna-kopyto',
        component: () => import('/home/node/app/src/pages/lekarze/katarzyna-kopyto.vue'),
        /* no children */
      },
      {
        path: 'przemyslaw-kopyto',
        name: '/lekarze/przemyslaw-kopyto',
        component: () => import('/home/node/app/src/pages/lekarze/przemyslaw-kopyto.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/umow-wizyte',
    name: '/umow-wizyte',
    component: () => import('/home/node/app/src/pages/umow-wizyte.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

